
import { Component, Vue } from 'vue-property-decorator'
import { initWangEdit } from '@/utils/wangEdit'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import { Info, PageType, Branch } from '@/types/plant'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})
export default class PlantAdd extends Vue {
  // 类型列表
  private plantTypeList = []
  // 科属所有内容
  private plantBranchList: Branch = {}
  private monthList = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
  // 花期选择数组
  private flowerTimeList = []
  // 果期选择数组
  private fruitingTimeList = []
  private plantTab = ['识别特征', '观赏特性', '养护要点', '生物学特征', '生态学特征', '品种介绍', '园林应用', '花语', '植物文化']
  private tabIndex = '0'
  private info: Info = {
    plantName: '',
    scientificName: '',
    plantOtherName: '',
    plantType: '',
    family: '',
    genus: '',
    flowerTime: '',
    fruitingTime: '',
    sourceArea: '',
    resourceList: [],
    plantIntroduce: '',
    watchFeature: '',
    maintainPoint: '',
    morphologies: '',
    growthHabit: '',
    typeIntroduce: '',
    parkApplication: '',
    flowerMark: '',
    culturalMark: ''
  }

  private rules = {
    plantName: [{ required: true, message: '请输入植物名称', trigger: ['blur', 'change'] }],
    scientificName: [{ required: true, message: '请输入学称', trigger: ['blur', 'change'] }],
    plantType: [{ required: true, message: '请选择植物类别', trigger: ['change'] }],
    family: [{ required: true, message: '请输入科名称', trigger: ['change'] }],
    genus: [{ required: true, message: '请输入属名称', trigger: ['change'] }],
    resourceList: [{ required: true, message: '请选择上传植物图片', trigger: ['change'] }],
    plantIntroduce: [{ required: true, message: '请输入识别特征', trigger: ['blur', 'change'] }],
    watchFeature: [{ required: true, message: '请输入观赏特性', trigger: ['blur', 'change'] }],
    maintainPoint: [{ required: true, message: '请输入养护要点', trigger: ['blur', 'change'] }]
  }

  private editorPlantIntroduce: any = null
  private editorWatchFeature: any = null
  private editorMaintainPoint: any = null
  private editorMorphologies: any = null
  private editorGrowthHabit: any = null
  private editorTypeIntroduce: any = null
  private editorParkApplication: any = null
  private editorFlowerMark: any = null
  private editorCulturalMark: any = null

  private submitShow = false

  get plantId () {
    return this.$route.params.id || ''
  }

  destroyed () {
    // 销毁编辑器
    this.editorPlantIntroduce.destroy()
    this.editorWatchFeature.destroy()
    this.editorMaintainPoint.destroy()
    this.editorMorphologies.destroy()
    this.editorGrowthHabit.destroy()
    this.editorTypeIntroduce.destroy()
    this.editorParkApplication.destroy()
    this.editorFlowerMark.destroy()
    this.editorCulturalMark.destroy()
    this.editorPlantIntroduce = null
    this.editorWatchFeature = null
    this.editorMaintainPoint = null
    this.editorMorphologies = null
    this.editorGrowthHabit = null
    this.editorTypeIntroduce = null
    this.editorParkApplication = null
    this.editorFlowerMark = null
    this.editorCulturalMark = null
  }

  created () {
    this.getTypeList()
    this.getPlantList()
    this.init()
    if (this.plantId) {
      this.getDetail()
    }
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.thinktankTypeList, {
      dicType: 'plantType'
    }).then((res) => {
      this.plantTypeList = res.list || []
    })
  }

  getPlantList () {
    this.$axios.get(this.$apis.plant.plantBranchList).then((res) => {
      this.plantBranchList = res
    })
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.editorPlantIntroduce = initWangEdit('#plantIntroduce', { placeholder: '请输入识别特征' })
      this.editorPlantIntroduce.create()
      this.editorWatchFeature = initWangEdit('#watchFeature', { placeholder: '请输入观赏特性' })
      this.editorWatchFeature.create()
      this.editorMaintainPoint = initWangEdit('#maintainPoint', { placeholder: '请输入养护要点' })
      this.editorMaintainPoint.create()
      this.editorMorphologies = initWangEdit('#morphologies', { placeholder: '请输入生物学特征' })
      this.editorMorphologies.create()
      this.editorGrowthHabit = initWangEdit('#growthHabit', { placeholder: '请输入生态学特征' })
      this.editorGrowthHabit.create()
      this.editorTypeIntroduce = initWangEdit('#typeIntroduce', { placeholder: '请输入品种介绍' })
      this.editorTypeIntroduce.create()
      this.editorParkApplication = initWangEdit('#parkApplication', { placeholder: '请输入园林应用' })
      this.editorParkApplication.create()
      this.editorFlowerMark = initWangEdit('#flowerMark', { placeholder: '请输入花语' })
      this.editorFlowerMark.create()
      this.editorCulturalMark = initWangEdit('#culturalMark', { placeholder: '请输入植物文化' })
      this.editorCulturalMark.create()
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.plant.plantDetail, {
      plantId: this.plantId
    }).then((res) => {
      this.info = res
      this.flowerTimeList = res.flowerTimeList ? res.flowerTimeList : []
      this.fruitingTimeList = res.fruitingTimeList ? res.fruitingTimeList : []
      this.editorPlantIntroduce.txt.html(res.plantIntroduce || '')
      this.editorWatchFeature.txt.html(res.watchFeature || '')
      this.editorMaintainPoint.txt.html(res.maintainPoint || '')
      this.editorMorphologies.txt.html(res.morphologies || '')
      this.editorGrowthHabit.txt.html(res.growthHabit || '')
      this.editorTypeIntroduce.txt.html(res.typeIntroduce || '')
      this.editorParkApplication.txt.html(res.parkApplication || '')
      this.editorFlowerMark.txt.html(res.flowerMark || '')
      this.editorCulturalMark.txt.html(res.culturalMark || '')
    })
  }

  // 输入建议
  querySearch (name: string) {
    return (queryString: string, cb: Function) => {
      const plantBranchList = this.plantBranchList[name]
      const results = queryString ? plantBranchList.filter(this.createFilter(queryString, name)) : plantBranchList
      // 调用 callback 返回建议列表的数据
      cb(results)
    }
  }

  createFilter (queryString: string, name: string) {
    return (item: PageType) => {
      return (item[name].toLowerCase().indexOf(queryString.toLowerCase()) === 0)
    }
  }

  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  onSubmit () {
    // 必填项赋值
    // 获取富文本内容
    this.info.plantIntroduce = this.editorPlantIntroduce.txt.html()
    this.info.watchFeature = this.editorWatchFeature.txt.html()
    this.info.maintainPoint = this.editorMaintainPoint.txt.html()
    this.tabIndex = this.info.plantIntroduce === '' ? '0' : this.info.watchFeature === '' ? '1' : this.info.maintainPoint === '' ? '2' : this.tabIndex
    ;(this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.info.morphologies = this.editorMorphologies.txt.html()
        this.info.growthHabit = this.editorGrowthHabit.txt.html()
        this.info.typeIntroduce = this.editorTypeIntroduce.txt.html()
        this.info.parkApplication = this.editorParkApplication.txt.html()
        this.info.flowerMark = this.editorFlowerMark.txt.html()
        this.info.culturalMark = this.editorCulturalMark.txt.html()
        this.info.flowerTime = this.flowerTimeList.join(',')
        this.info.fruitingTime = this.fruitingTimeList.join(',')
        const url = this.plantId ? this.$apis.plant.plantUpdate : this.$apis.plant.plantAdd
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'plantList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
